import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';
import { useSpring } from 'react-spring';

import Layout from '@components/common/layout';
import Hero from '@components/en/common/hero';
import { News } from '@components/news/svgs';
import { NewsList,  ContactIntro } from '@components/en/news/styles';
import Pager from '@components/news/pager';
import { color } from '@src/settings';


const NewsPage = ({ data }) => {

	/* ----------------------------------------------------
	* smoothScroll
	* ---------------------------------------------------*/	

	const [, setY] = useSpring(() => ({ y: 0 }));

	const boxRef = useRef();

	let isStopped = false;
	const onWheel = () => {
		isStopped = true;
		window.removeEventListener('wheel', onWheel)
	}
	const scrollToTarget = (targetRef) => {
		const target = targetRef.current;
		const val = window.scrollY + target.getBoundingClientRect().top
		window.addEventListener('wheel', onWheel);
		setY({
			y: val,
			reset: true,
			from: { y: window.scrollY },
			onRest: () => {
				isStopped = false;
				window.removeEventListener('wheel', onWheel)
			},
			onFrame: props => {
				if(!isStopped){
					window.scroll(0,props.y)
				}
			}
		})
	}

	/* smoothScroll
	* ---------------------------------------------------*/

	/* ----------------------------------------------------
	* 投稿表示の設定
	* ---------------------------------------------------*/

	const [page, updatePage] = useState(1);
	const handleUpdate = (pageNum) => {
		scrollToTarget(boxRef);
		updatePage(pageNum);
	}
	const viewSetting = {
		perPage: 10,	
	}

	const posts = [];
	data.posts.edges.map(post => {
		posts.push({
			date: post.node.date,
			title: post.node.title,
			to: `/news/${ post.node.wordpress_id }`
		});
	});

	const min = viewSetting.perPage*(page-1)
	const max = min + viewSetting.perPage
	const pageLen = Math.ceil(posts.length/viewSetting.perPage);
		
	const viewPosts = posts.slice(min,max);

	/* 投稿表示の設定
	* ---------------------------------------------------*/

	return (
		<Layout baseBgColor={ color.paleGray }>
			<Hero
				img={ data["heroImg"] }
				title={<News style={{ fill: "#FFF" }}/> }
				subTitle="お知らせ"
			/>
			<NewsList
				ref={ boxRef }
				posts={ viewPosts }
				noBtn
			/>
			{ pageLen > 1 && (
			<Pager
				cur={ page }
				pageLen={ Math.ceil(posts.length/viewSetting.perPage) }
				updateFunc={ handleUpdate }
			/>
			)}
			<ContactIntro />
		</Layout>
	)
}

export const query = graphql`
	query newsTop_en{

		posts: allWordpressPost(filter: {categories: {eq: 5}}, sort: {fields: date}) {
			edges {
				node {
					date(formatString: "Y.M.D")
					title
					wordpress_id
				}
			}
		},
		heroImg: file( relativePath: { eq: "news/hero-img_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},
	}
`

export default NewsPage;
